$-bg:black;
$-color: white;

@mixin configure($bg: null, $color) {
  @if $bg {
    $-bg: $bg !global;
  }
  @if $color {
    $-color: $color !global;
  }
}

@mixin main($bg, $color) {
  @include configure($bg: $bg, $color: $color);
  background-color: $-bg;
  color: $color;
}