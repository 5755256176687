.collapsible {
  font-family: 'DINPro';
  @apply leading-4;

  @apply border-t;
  @apply border-gray-200;

  span {
    @apply font-bold;
    @apply uppercase;
    @apply my-4;
    @apply inline-block;
  }

  p,
  span {
    @apply text-xs;
  }

  span {
    width: 90%;
    position: relative;

    &::after {
      @apply absolute;
      @apply top-1/2;
      @apply text-3xl;

      right: -1.5rem;
      padding-bottom: 10px;
      transform: translateY(-50%);
      content: '+';
    }

    &[aria-expanded='true'] {
      &::after {
        content: '-';
      }
    }
  }

  p {
    @apply mb-4;
  }
}