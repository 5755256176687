@mixin button($color: null, $border-color: null, $letter-spacing: null, $background: null, $radius: null, $family: null, $font-size: null, $font-weight: null, $padding: null, $height: null, $min-height: null, $text-align: null, $border: null) {
  .button-generic {
    
    @if $color {
      color: $color;
    }

    @if $letter-spacing {
      letter-spacing: $letter-spacing;
    }
    
    @if $border-color {
      border-color: $border-color;
    }

    @if $background {
      background-color: $background;
    }
    
    @if $radius {
      border-radius: $radius;
    }

    @if $family {
      font-family: $family;
    }

    @if $font-size {
      font-size: $font-size;
    }
    
    @if $font-weight {
      font-weight: $font-weight;
    }

    @if $padding {
      padding: $padding;
    }

    @if $height {
      height: $height;
    }

    @if $min-height {
      min-height: $min-height;
    }

    @if $text-align {
      text-align: $text-align;
    }

    @if $border {
      border: $border;
    }
  }
}

@mixin buttonProperty ($property: null, $color: null, $border-color: null, $letter-spacing: null, $background: null, $radius: null, $family: null, $font-size: null, $font-weight: null, $padding: null, $height: null, $line-height: null, $text-align: null) {
  #{$property} {
    
    @if $color {
      color: $color;
    }

    @if $letter-spacing {
      letter-spacing: $letter-spacing;
    }
    
    @if $border-color {
      border-color: $border-color;
    }

    @if $background {
      background-color: $background;
    }
    
    @if $radius {
      border-radius: $radius;
    }

    @if $family {
      font-family: $family;
    }

    @if $font-size {
      font-size: $font-size;
    }
    
    @if $font-weight {
      font-weight: $font-weight;
    }

    @if $padding {
      padding: $padding;
    }

    @if $height {
      height: $height;
    }

    @if $line-height {
      line-height: $line-height;
    }

    @if $text-align {
      text-align: $text-align;
    }
  }
}
