.wrapper {
  color: black;
  @media (max-width: 425px) {
    width: 100%;    
  }
  @apply p-7;

  &>div {
    @apply space-y-4;
  }

  h1 {
    @apply text-center;
    @apply text-5xl;
    @apply font-bold;
    @apply uppercase;
  }

  h1+p {
    @apply text-center;
    @apply text-xl;
  }

  h2 {
    @apply font-bold;
    @apply text-2xl;
  }

  ol {
    @apply ml-3;
    @apply space-y-1;
    list-style: upper-roman;
  }

  li {
    @apply text-justify;
  }
}