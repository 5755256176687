.container-grid {
  padding: 0 1.75rem 1.75rem;
  width: 100%;
  max-width: 56rem/* 896px */;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem/* 20px */;
  
  @media (min-width: 430px) {
    display: grid;
    grid-auto-rows: 268px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  @media (min-width: 640px) {
    grid-auto-rows: 268px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 830px) {
    grid-auto-rows: 320px;
  }
}

.container-height {
  height: 436px;

  @media (min-width: 430px) {
    height: 100%;
  }
}

.continue {
  display: flex; 
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  @media (min-width: 430px) {
    display: none;
  }
}