// CDN PHStorage
@import url('https://cdn-phcentral.azureedge.net/fonts/DIN/din.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Inter/inter.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Compacta/compacta.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Flama/flama.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/NersansThree/nersans.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Caveat/caveat.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Raleway/raleway.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Jost/jost.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/GearedSlab/garedSlab.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/BebasNeue/bebasneue.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Montserrat/montserrat.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Industry/industry.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Helvetica/helvetica.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/BebasNeuePro/bebasNeuePro.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/BoldBlaster/boldBlaster.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Gotham/gotham.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Frederick/frederick.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/OpenSans/openSans.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Fresno/fresno.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Termina/termina.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/HeadingProUltraComp/headingProUltraComp.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Oswald/oswald.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/AcuminPro/acuminPro.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Antonio/antonio.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/HeinekenSerif/heinekenSerif.font.css');
@import url('https://cdn-phcentral.azureedge.net/fonts/Propaganda/propaganda.css');

// Google Api
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@200;300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.pt-sans-regular {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.pt-sans-bold {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.pt-sans-regular-italic {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.pt-sans-bold-italic {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.source-serif-pro {
  font-family: 'Source Serif Pro', serif;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.roboto-condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

// -- Page With Key View --
.page-with-key {
  &__section {
    color: black;
    @media (max-width: 425px) {
      width: 100%;    
    }
  
    &>div {
      @apply space-y-4;
    }
  
    h1 {
      @apply text-center;
      @apply text-5xl;
      @apply font-bold;
      @apply uppercase;
    }
  
    h1+p {
      @apply text-center;
      @apply text-xl;
    }
  
    h2 {
      @apply font-bold;
      @apply text-2xl;
    }
  
    ol {
      @apply ml-3;
      @apply space-y-1;
      list-style: upper-roman;
    }
  
    li {
      @apply text-justify;
    }
  }
}

.sello {
 max-width: 75px;
}

.animations-stepper {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: absolute;

  &-hidden {
    transform: translateY(90vh);
  }

  &-show {
    transform: translateY(0vh);
  }
}

.animations-scratch {
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  
  &-show {
    .title, .description {
      display: block;
      opacity: 1;
    }

    .body {
      justify-content: center;
      gap: 24px;
    }

    .scratch {
      &__info {
        &-cnt {
          display: none;
        }
      }
    }

    .button-generic {
      opacity: 1;
    }
  }
}