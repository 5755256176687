@use '../../styles/theme/components/main';
@use '../../styles/theme/components/backgroundImage';
@use '../../styles/theme/components/texts';
@use '../../styles/theme/components/button';
@import '../../styles/vars.scss';

.silver {

  // -- Components -- 

  .title {
    &.big {
      font-family: Antonio;
      font-size: 40px;
      line-height: normal;
      font-weight: 700;
      text-transform: uppercase;
    }

    &.medium {
      font-family: Antonio;
      font-size: 32px;
      line-height: normal;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: -0.64px;
    }

    &.small {
      font-family: Antonio;
      font-size: 24px;
      line-height: normal;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: -0.64px;
    }
  }

  .description {
    &.medium {
    font-family: Helvetica;
    font-size: 16px;
    line-height: 20px;
    }
  }

  .text {
    &.medium {
      font-family: Helvetica;
      font-size: 16px;
      line-height: 20px;
    }

    &.small {
      font-family: Helvetica;
      font-size: 10px;
      line-height: 20px;
    }
  }

  .button {
    cursor: pointer;

    &.underline {
      font-family: Helvetica;
      background-color: none;
      border: none;
      text-decoration: underline;
      &.medium {
        font-size: 16px;
      }
    }
  }

    // -- Main --
    @mixin mainPrimary {
      @include main.main($bg: #FFF, $color: #07672F);
    }

    // -- Header --
    @mixin headerPrimary {
      .header-app {
        background-color: white;

        .logo {
          width: auto;
        }

        &__button-container {
          background-color: white;
          .button-span {
            background-color: #07672F;
          }
        }

        &__menu {
          background-color: #07672F;
          color: var(--color-tertiary);
          .top-container {
            @include titlePrimary();
            &__title {
              padding-top: 48px;
              color: white;
              font-family: Antonio;
              font-size: 48px;
              font-weight: 700;
              line-height: 120%;
              text-transform: uppercase;
            }
          }

          .links-container {
            p, h2 {
              margin-top: 6px;
            }

            .text-small {
              color: #FFF;
              font-family: "Helvetica";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 12px;
              text-transform: none;
            }

            .text-big {
              color: #FFF;
              font-family: Antonio;
              font-size: 21px;
              font-style: normal;
              font-weight: 700;
              line-height: 30px;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    // -- Steps -- 

    @mixin stepPrimary {
      .step {
        color: rgba(0, 33, 68, 0.20);
        text-align: right;
        font-family: "Open Sans";
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;

        &-active {
          color: var(--color-secondary);
        }
      }

      .step-container {
        .step-text {
          color: #07672F;
          font-family: "Helvetica";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 6px;

          .step-total {
            opacity: 1;
          }
        }

        .step-bar {
          background-color: #BABBBF;
          height: 2px;

          .step-progress {
            height: 2px;
            background-color: #E30613;
          }
        }
      }
    }

    // -- Input -- 
    @mixin inputLabel {
      @include texts.text($property: '.input-label', $color: #07672F, $size: 16px, $weight: 700, $family: "Antonio", $height: normal, $transform: none);
    }

    @mixin inputLabelCheckbox {
      @include texts.text($property: '.input-checkbox-label', $color: #07672F, $size: 12px, $weight: 400, $family: "Helvetica", $height: normal, $transform: none);
    }

    @mixin inputLabelRadio {
      @include texts.text($property: '.input-radio-label', $color: #07672F, $size: 12px, $weight: 400, $family: "Helvetica", $height: normal, $transform: none);
    }

    @mixin inputPrimary {
      .input-container {
        .input-generic {
          height: 50px;
          padding: 16.406px 25.16px 16.406px 16px;
          color: #07672F;
          font-family: "Helvetica";
          font-size: 14px;
          font-weight: 400;
          text-transform: none;
          text-align: left;
          border: 1px solid #07672F;
          border-radius: 8px;

          &::placeholder {
            color: #BABBBF;
          }

          &:disabled {
            opacity: 0.5;
          }
        }

        .input-password-container {
          height: 50px;
          padding: 16.406px 25.16px 16.406px 16px;
          border: 1px solid #07672F;
          border-radius: 8px;

          .input-password {
            color: #07672F;
            font-family: "Helvetica";
            font-size: 14px;
            font-weight: 400;
            text-transform: none;
            text-align: left;
            
            &::placeholder {
              color: #BABBBF;
            }
          }

          .input-icon-show {
            color: #07672F;
          }

          .input-icon-not-show {
            color: #07672F;
          }
        }

        .input-agegate-container {
          height: 50px;
          padding: 16.406px 25.16px 16.406px 16px;
          color: #07672F;
          font-family: "Helvetica";
          border: 1px solid #07672F;
          border-radius: 8px;

          .input-agegate {
            height: 100%;
            font-size: 14px;
            font-weight: 400;
            &::placeholder {
              color: #002144;
            }
          }
        }

        .input-radio-container {
          color: #07672F;
          font-family: "Roboto";

          .input-radio {
            border: 1px solid #07672F;

            &:checked::after {
              background-color: #07672F;
            }
          }
        }

        .input-select {
          height: 50px;
          padding: 14.406px 25.16px 18.406px 16px;
          color: #07672F;
          font-family: "Helvetica";
          border: 1px solid #07672F;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 400;
          text-transform: none;
          min-height: auto;
        }

        .input-checkbox {
          border: 1px solid #07672F;
          border-radius: 4px;
          width: 24px;
          height: 24px;
        }

        .input-checkbox-active {
          color: white;
          background-color: #07672F;
        }

        .input-otp-container {
          width: 100%;
          .input-otp {
            color: #007F2D;
            font-family: "Helvetica";
            font-size: 14px;
            font-weight: 400;
            text-transform: none;
            width: 15%;
            margin: 0;
            height: 50px;
            background-color: #EFFFF1;
            border-radius: 8px;
            border: 1px solid #007F2D;
            aspect-ratio: 1/1;

            &:invalid {
              background-color: #FFF;
            } 

            &:valid {
              background-color: #EFFFF1;
            }
          }

        }

        @include inputLabel();
        @include inputLabelCheckbox();
        @include inputLabelRadio();
      }

      .input-error {
        font-family: "Helvetica";
        color: rgb(120, 0, 0);
        font-size: 12px;
      }

      .title-street {
        color: #07672F;
        text-align: center;
        font-family: "Helvetica";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      .subtitle-street {
        color: #07672F;
        text-align: center;
        font-family: "Helvetica";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .otp-text-container {
        .otp-title {
          color: #272727;
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
        }
        
        .otp-text, .otp-text-secondary, .otp-time {
          color: #272727;
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .otp-time {
          align-items: center;
          font-weight: 700;
        }

        .otp-text-resend-container {
          .text-resend, .text-resend-button {
            color: #002144;
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .text-resend-button {
            font-weight: 700;
          }
          
        }
      }
    }

    // -- Button --
    @mixin buttonGeneric {
      @include button.button($color: white, $border-color: '', $letter-spacing: null, $background: #07672F, $radius: 0, $family: "Helvetica", $font-size: 14px, $font-weight: 700, $padding: 16px, $height: auto, $min-height: auto);
    }

    // -- Title --
    @mixin titlePrimary {
      @include texts.text($property: '&__title', $color: #07672F, $size: 32px, $weight: 700, $family: "Antonio", $height: 32px, $transform: uppercase);
    }

    // -- Subtitle --
    @mixin subtitlePrimary {
      @include texts.text($property: '&__subtitle', $color: #007F2D, $size: 16px, $weight: 700, $family: 'Helvetica', $height: 20px, $transform: none);
    }

    // -- Description --
    @mixin descriptionPrimary {
      @include texts.text($property: '&__description', $color: #007F2D, $size: 16px, $weight: 400, $family: "Helvetica", $height: 20px, $transform: none);
    }

    // -- Text --
    @mixin textPrimary {
      @include texts.text($property: '.text', $color: #007F2D, $size: 16px, $weight: 400, $family: "Antonio", $height: 110%, $transform: none);
    }

    // -- BackButton --
    @mixin backButtonPrimary {
      @include texts.text($property: '.button-back', $color: #BABBBF, $size: 10px, $weight: 400, $family: "Helvetica", $height: normal, $transform: uppercase);
    }
  // -- Finish Components --

  // -- Themes --
  @mixin themePrimary {
    background: none;
    @include mainPrimary();
    @include headerPrimary();
    @include buttonGeneric();
    @include titlePrimary();
    @include backButtonPrimary();
  }
  @mixin themeSecondary {
    background: #EFFFF1;
    color: #07672F;
    @include headerPrimary();
    @include buttonGeneric();
    @include titlePrimary();
    @include subtitlePrimary();
    @include descriptionPrimary();
    @include backButtonPrimary();
  }
  // -- Finish Themes --  

  @include headerPrimary();
  @include inputPrimary();
  @include stepPrimary();
  @include buttonGeneric();
  @include backButtonPrimary();

  // -- Views --
    .wifi {
      &__callback {
        color: #007F2D;
      }
    }

    .top-container {
      &__title {
        &__name {
          color: #BABBBF;
        }
      }
    }

    // -- Agegate --
    .agegate {
      .header-app {
        display: none;
      }

      &__description {
        color: #07672F;
        text-align: center;
        font-family: "Helvetica";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: none;
        padding: 24px;
      }

      &__politics {
        color: #BBBCBD;
        text-align: center;
        font-family: Helvetica;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__input {
        &__container {
          &__day, &__month, &__year {
            input {
              color: #07672F;
              text-align: center;
              font-family: Antonio;
              font-size: 48px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              text-transform: uppercase;

              &::placeholder {
                opacity: 0.2;
              }
            }

            p {
              color: #BABBBF;
              text-align: center;
              font-family: "Helvetica";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              text-transform: uppercase;
            }
          }

          &__month {
            border-left: 1px solid #E30613;
            border-right: 1px solid #E30613;
          }
        }
      }
    }

    // -- End Route --
    .endroute {
      @include themeSecondary();

      &__title {}
      &__description {}
    }

    // -- Start --
    .start {
      @include textPrimary();
      @include button.buttonProperty($property: '.sign-in', $color: white, $letter-spacing: null, $background: #07672F, $radius: 0, $family: 'Helvetica', $font-size: 14px, $font-weight: 700, $padding: 16px, $height: auto, $text-align: center);
      @include button.buttonProperty($property: '.sign-up', $color: white, $letter-spacing: null, $background: #BABBBF, $radius: 0, $family: 'Helvetica', $font-size: 14px, $font-weight: 700, $padding: 16px, $height: auto, $text-align: center);

      .social {
        border: 2px solid #BABBBF;
        .facebook, .google {
          width: 50px;
          height: 50px;
        }
      }

      // -- Allow Cookies --
      &__allow-cookies {
        .input-checkbox-label {
          flex: 1;
        }
      }

      &__popup {
        background-color: #EFFFF1;

        .popup {
          &-title {
            color: #07672F;
            text-align: center;
            font-family: Antonio;
            font-size: 26px;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.52px;
            text-transform: uppercase;
          }

          &-description {
            color: #007F2D;
            text-align: center;
            font-family: "Helvetica";
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }

     .trivia {
      &__ranking {
        @include themeSecondary();
        .button-top {
          text-align: center;
          font-family: Helvetica;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
          border: 1px solid #007F2D;
        }
        
        .information-position {
          border-color: #007F2D;
          .division{
          }
        }

        .ranking {
          &-cards {

          }

          &-position {
            border-color: #007F2D;

            p {
              line-height: 24px;
              margin-bottom: 3px;
            }
          }
        }
      }
    }

    // -- Sign Up --
    .sign-up {
      // -- Welcome --
      &__welcome {
        @include themeSecondary();
        &__title {
          font-size: 40px;
          line-height: 40px;
        }
        &__subtitle {
          font-family: Antonio;
          color: #007F2D;
          text-transform: uppercase;
        }
        &__description {
          // &.2 {}
          // &.3 {}
        }
        &__resend {
          color: #007F2D;
          font-family: "Helvetica";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-decoration-line: underline;
        }

        // -- OTP --
        &__otp {
          @include themePrimary();
          &__email {}
          &__subtitle {
            color: #007F2D;
            text-align: center;
            font-family: "Helvetica";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            text-transform: none;
          }
          &__description {
            color: #007F2D;
            text-align: center;
            font-family: "Helvetica";
            font-size: 16px;
            font-style: normal;
            line-height: 20px;
          }
        }
      }
      
      // -- Done --
      &__done {
        @include themeSecondary();
        &__title {}
        &__description {}
      }
    }

    // -- Sing In --
    .login {    
      &__title {
        color: #BABBBF;
        font-family: Antonio;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.64px;
        text-transform: uppercase;
        text-align: left;
      }
      
      &__help {
        display: none;
      }

      &__recovery {
        color: #BABBBF;
        text-align: center;
        font-family: "Helvetica";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: uppercase;
      }

      &__mail-sent-password {
        @include themeSecondary();

        .login__recovery {
          color: #007F2D;
          text-align: center;
          font-family: "Helvetica";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; 
          text-transform: none;
        }
      }

      &__password-reset {
        &__title {
          color: #BABBBF;
          font-family: Antonio;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.64px;
          text-transform: uppercase;
          text-align: left;
        }
      }

      &__help {
        color: #007F2D;
        text-align: center;
        font-family: "Helvetica";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }

    // -- Home --
    .home {
      &__title-container {
        display: flex;
        gap: 4px;
        align-items: center;
        
        .title-1, .title-2 {
          color: #007F2D;
          font-family: Antonio;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 110%;
          letter-spacing: -0.36px;
          text-transform: none;
        }

        .title-2 {
          color: #BABBBF;
        }
      }

      hr {
        border-color: #07672F;
        border-top-width: 1px;
      }

      .text {
        color: #07672F;
        font-family: "Helvetica";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .go-arrow {
        display: none;
      }

      .progress-container {
        border-radius: 4px;
        border: 1px solid #E30613;
        background: #FFF;

        .progress-complete {
          border-radius: 2px;
          background: #E30613;
        }
      }
    }

    // -- Trivias --
    .trivias {
      background: #EFFFF1;

      &__container {
        width: 100%;
        padding: 21px 16px;

        &__cards {
          @apply grid grid-cols-1 md:gap-4 md:grid-cols-1 lg:grid-cols-1 auto-rows-fr;
        }
      }

      &__title {
        color: var(--HNK-SILVER-Celeste, #007F2D);
        font-family: Antonio;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-transform: uppercase;

        &-cnt {
          padding: 0px 0px 24px;
        }
      }

      &__card-list {

        .card-container {
          background-color: white;
          border: 1px solid white;
          border-radius: 8px;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);

          .card-title {
            &-cnt {
              border-bottom: 1px solid white;
              border-radius: 8px 8px 0px 0;
              background-color: #BABBBF;
            }

            &-int {
              border-right: 1px solid white;
              color: #FFF;
              text-align: center;
              font-family: Antonio;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 110%;
              text-transform: uppercase;
            }

            &-name {
              color: #FFF;
              font-family: "Helvetica";
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
            }
          }

          .card-body {
            &-subtitle {
              color: #007F2D;
              text-align: center;
              font-family: "Helvetica";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;

              &-question {
                color: #007F2D;
                font-weight: 700;
              }
            }

            &-prize {
              color: #007F2D;
              font-family: Antonio;
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: -0.64px;
              text-transform: uppercase;
            }

            &-stock {
              color: #007F2D;
              text-align: center;
              font-family: Antonio;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 110%;
              text-transform: uppercase;
            }
          }

          .card-footer {
            width: 100%;
            gap: 8px;
            padding-bottom: 8px;
            &-time {
              &-cnt {
                width: auto;

                p {
                  color: #07672F;
                  font-family: "Helvetica";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-transform: none;

                  br {
                    display: none;
                  }
                }
              }

              &-countdown {
                color: #07672F;
                font-family: "Helvetica";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            &-cost-free, &-cost {
              width: auto;
              color: #07672F;
              font-family: Antonio;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              text-transform: uppercase;
            }
          }
        }

        &-active {

        }
      }

      &__not-found {
        &-container {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding: 26px;
        }

        &-text {
          color: #007F2D;
          text-align: center;
          font-family: "Helvetica";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      &__popup {
        &__intro {
          background-color: #EFFFF1;


          .popup__intro {
            &-title {
              color: #007F2D;
              text-align: center;
              font-family: Antonio;
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: -0.64px;
              text-transform: uppercase;
            }

            &-description {
              color: #007F2D;
              text-align: center;
              font-family: "Helvetica";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }

            &-warning {
              color:#007F2D;
              font-family: "Helvetica";
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px;
            }

            &-information {
              display: none;
              color: #007F2D;

              .text {
                text-transform: uppercase;
              }

              .description {
                border-color: #007F2D;
                font-weight: 700;
              }
            }
          }
        }

        &__intro-img {
          max-width: 343px;
        }
      }

      &__trivia__trivia-game {
        &__selector {
          background-color: white;

          .trivia-game {
            &-cnt {

            }

            &-head {
              background-color: #BABBBF;

              &-int {
                color: #FFF;
                text-align: center;
                font-family: Antonio;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%;
                text-transform: uppercase;
                border-right: 1px solid white;
              }

              &-name {
                color: #FFF;
                font-family: "Helvetica";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px; 
                text-transform: none;
              }

              &-question {
                border-bottom: 1px solid #BABBBF;
                padding: 8px 16px;
                align-items: flex-start;
                gap: 8px;

                &-num {
                  color: #007F2D;
                  font-family: "Helvetica";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 20px;
                  padding: 0px;
                }

                &-text {
                  color: #007F2D;
                  font-family: "Helvetica";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  text-transform: none;
                  padding: 0px;
                }
              }
            }

            &-body {
              padding: 16px;
              gap: 8px;
              display: flex;
              flex-direction: column;

              &-selector {
                border-radius: 8px;
                border: 1px solid rgba(0, 127, 45, 0.75);
                background: rgba(0, 127, 45, 0.04);
                height: auto;
                padding: 35px 16px;

                &-li {
                  border-color: #007F2D;
                }

                &-text {
                  color: #007F2D;
                  font-family: Antonio;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  letter-spacing: -0.48px;
                }
              }
            }

            &-footer {
              &-countdown {
                background: none;
                border: 4px solid #BABBBF;
                border-radius: 999px;

                &-text {
                  color: #BABBBF;
                  font-family: Antonio;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 120%;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }

      &__game {
        &__alert {
          background-color: #EFFFF1;

          &__title {
            color: #007F2D;
            text-align: center;
            font-family: Antonio;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.64px;
            text-transform: uppercase;
          }

          &__subtitle {
            color: #007F2D;
            text-align: center;
            font-family: "Helvetica";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      &__exchange {
        background-color: #EFFFF1;

        .title {
          color: #007F2D;
          text-align: center;
          font-family: Antonio;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.64px;
          text-transform: uppercase;

          br {
            display: none;
          }
        }

        .description {
          color: #007F2D;
          text-align: center;
          font-family: Antonio;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          text-transform: uppercase;
        }
      }
    }

    .trivia-qr {
      &__pop-up {
        .pop-up-body {
          justify-content: flex-start;
          gap: 16px;

          &-title {
            color: #007F2D;
            text-align: center;
            font-family: Antonio;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
          }

          &-img {
            width: 100%;
            border-radius: 16px;
            border: 2px solid #007F2D;
          }

          &-subtitle {
            color: #007F2D;
            text-align: center;
            font-family: Antonio;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
          }

          &-description {
            color: #007F2D;
            text-align: center;
            font-family: "Helvetica";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px
          }
        }
      }
    }

    .survey {
      &-qr {
        &__pop-up {
          h3 {
            color: #007F2D;
            text-align: center;
            font-family: Antonio;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
          }

          h4 {
            color: #007F2D;
            text-align: center;
            font-family: Antonio;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
          }

          p {
            color: #007F2D;
            text-align: center;
            font-family: "Helvetica";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      &__stepper {
        background: #EFFFF1;
        &-header {

          &-title {
            color: #007F2D;
            font-family: Antonio;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.64px;
            text-transform: uppercase;
          }

          &-description {
            color: #007F2D;
            font-family: "Helvetica";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        &-body {
          background-color: white;
        }
      }

      .label-survey {
        padding: 0 0 16px 0;

        h2, h3 {
          color: #007F2D;
          font-family: Antonio;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: left;
        }
        
        h3 {
          color: #BABBBF;
          font-size: 20px;
        }
      }

      .multiselect-item {
        div {
          border-radius: 8px;

          &.border-black {
            border-color: #E30613;
          }

          p {
            color: #FFF;
            text-align: center;
            font-family: "Helvetica";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
            z-index: 5;
          }

          img {
            border-radius: 6px;
            filter: contrast(0.9) brightness(0.8);
          }
        }
      }

      .input-radio-container {
        .radio {
          &__input {
            border: 1px solid #007118;

            &:checked {
              &::after {
                background-color: #007118;
              }
            }
          }

          &__label {
            color: #007118;
            font-family: "Helvetica";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      select {
        height: 50px;
        padding: 14.406px 25.16px 18.406px 16px;
        color: #007F2D;
        font-family: "Helvetica";
        border: 1px solid #007F2D;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        min-height: auto;

        option {
          color: #BABBBF;
          font-family: "Helvetica Neue LT Std";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }

      .checkbox {
        .input-checkbox {
          border: 1px solid #007F2D;
          border-radius: 4px;
          width: 24px;
          height: 24px;
        }

        .input-checkbox-active {
          color: white;
          background-color: #007F2D;
        }

        &__label {
          color: #07672F;
          font-family: "Helvetica";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

      }

      .scratch {
        &__info {
          &-cnt {
            background: rgba(186, 187, 191, 0.16);
          }

          &-text {
            color: #007F2D;
            font-family: "Helvetica";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          &-svg {
            path {
               fill: #007F2D;
            }
          }
        }
      }

      .input-survey-textarea {
        color: #007F2D;
        font-family: "Helvetica";
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        text-align: left;
        border: 1px solid #007F2D;
        border-radius: 8px;

        &::placeholder {
          color: #BABBBF;
        }

        &:disabled {
          opacity: 0.5;
        }

        padding: 20px;
        min-height: 120px;
        max-height: 400px;
      }
    }

    // -- Profile --

    .profile {
      &__history {
        &__button {
          &__base {}
  
          &__active {}
  
          &__progress { }
  
          &__finish {}
        }
  
        &__popup {
          &__coupon {
            &__container {}
  
            &__title {
              &__exchange {}
            }
  
            &__text {}
  
            &__text-bold {}
  
            &__back-btn {}
  
            &__timer {}
          }
        }
      }

      .menuprofile {
        &__container {
          padding: 19px 16px;
          justify-content: flex-start;
          border-bottom: 1px solid #BABBBF;

          a {
            display: none;
          }

          h1 {
            color: #007F2D;
            font-family: Antonio;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-transform: uppercase;
          }
        }

        &__list-container {
          .list-item-container {
            padding: 0;
            border-bottom: 1px solid #BABBBF;

            a {
              padding: 14px 16px;

              .link-container {
                .link-icon {
                  color: #007F2D;
                }

                .link-label {
                  color: #007F2D;
                  font-family: "Helvetica";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }

              .icon-arrow {
                rotate: 180deg;

                svg {
                  height: 16px;
                  width: 12px;
                  color: #007F2D;
                }
              }
            }

            &:hover {
              background-color: #BABBBF;

              a {
                .link-container {
                  .link-icon {
                    color: white;
                  }

                  .link-label {
                    color: white;
                  }
                }

                .icon-arrow {
                  svg {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }

      &__title-container {
        padding: 19px 16px;
        border-bottom: 1px solid #BABBBF;

        div {

          svg {
            color: #BABBBF;

            path {
              fill: #BABBBF;
            }
          }

          .title {
            color: #007F2D;
            font-family: Antonio;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
          }
        }

        .icon-edit {
          svg {
            color: #BABBBF;
          }
        }
      }

      &__label {
        color: #007F2D;
        font-family: "Helvetica";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: none;
      }

      &__response {
        color: #007F2D;
        font-family: "Helvetica";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .state {
        &-bg {
          background: #EFFFF1;
          border: 0px;
        }

        &-container {
          border-bottom: 0px;
        }

        &-image-container {
          .state {
            &-crown {
              border-radius: 9999px;
              border: 6px solid #007F2D;

              p {
                color: #007F2D;
                font-family: Antonio;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
              }
            }
          }
        }

        &-text-container {
          .state {
            &-title {
              color: #007F2D;
              font-family: Antonio;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              text-transform: uppercase;
            }

            &-text {
              color: #007F2D;
              font-family: "Helvetica";
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;

              &-date {
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    // -- Redeem --
    .redeem {
      
    }

    // -- Memorama --
    .memorama {
      // -- List --
      &__list {
        // -- PopUp Play --
        &__play {}
      }

      &__game {
        &__alert {}
      }
    }

    // -- Album --
    .album {
      // -- Multimedia --
      &__multimedia {
        // -- Ranking --
        &__ranking {
          .title {}
          .status {
            &__finished {}
          }
          .status-date {
            &__finished {}
          }
          .button-top {
            &__finished {}
          }
          .subtitle {}
          .card {
            .image {}
            .container-bottom {
              .container-likes {
                .position {}
                .like {}
              }
              .container-texts {
                .title {}
                .description {}
              }
            }
          }
        }

        // -- Image ID --
        &__image-id {
          .title {}
          .button-back {}
          .card {
            .image {}
            .container-bottom {
              .like {}
              .container-texts {
                .title {}
                .description {}
              }
              .shared {}
            }
          }

          // -- Voted --
          &__voted {
            .title {}
            .icon {}
          }
        }

        // -- Upload --
        &__upload {
          .title {}
          .button-back {}
          .button-upload {}
          .rules-container {
            .rules-title {}
            .rules-content {}
          }

          // -- Camera --
          .camera {
            .webcam {}
            .button-capture {}
          }

          // -- Already Image --
          .already-image {
            .card {
              .image {}
            }
            .button-generic {}
            .button-cancel {}
          }

          // -- How To Participate --
          &__how-to-participate {
            .container {  
              .title {}
              .subtitle {}
              .content {}
            }
          }

          // -- Rules --
          &__rules {
            .container {  
              .title {}
              .content {}
            }
          }

          // -- Validate Error -- 
          &__view-error {
            .title {}
            .subtitle {}
          }

          // -- Ready --
          &__ready {
            .icon {}
            .title {}
            .description {}
            .button-back {}
          }

          // -- Loader validate --
          .loader-validate {
            .title {}
            .subtitle {}
          }
        }

        // -- Profile --
        &__profile {
          .title {}
          .not-found {}
          .button-back {}
        }
      }
    }

    // -- Wifi --
    .wifi {
      background-color: #EFFFF1;
      background-image: url('https://stblobphcentral.blob.core.windows.net/silver/assets/wifi-bg.webp');
      background-repeat: repeat;
      background-position: center;
      background-size: contain;
      &__data, &__success, &__error {
        color: #007F2D;

        .button-generic {
          &:disabled {
            background-color: #b7b7b7;
            color: #e7e7e7;
            border: 0px;
          }
        }
      }
    }

    // -- Términos y condiciones --
    .terms-and-conditions {
      background-color: white;
      .container-terms {      
        h1, h1 span {
          color: #007F2D !important;
          text-align: center !important;
          font-family: Antonio !important;
          font-size: 24px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 24px !important;
          text-transform: uppercase !important;
        }
        
        p, p span, h2, h2 span, li span, li {
          color: #07672F !important;
          text-align: justify !important;
          font-family: "Helvetica" !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }
      }
    }

    // -- Privacy --
    .privacy-politics {
      background-color: white;
      .container-priv {      
        h1, h1 span, h2 span, h2 {
          color: #007F2D !important;
          text-align: center !important;
          font-family: Antonio !important;
          font-size: 24px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 24px !important;
          text-transform: uppercase !important;
        }
        
        p, p span, li, h4, a, b  {
          color: #07672F !important;
          text-align: justify !important;
          font-family: "Helvetica" !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }
      }
    }

    // -- Help --
    .help {
      &__title-faq {
        color: #007F2D;
        text-align: center;
        font-family: Antonio;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-transform: uppercase;
      }

      &__subtitle-faq {
        color: #07672F;
        text-align: center;
        font-family: "Helvetica";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: none;
      }

      &__collapsible {
        &-cnt {
          border-color: #BABBBF;
        }

        &-text {
          span {
            color: #007F2D;
            font-family: Antonio;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            letter-spacing: -0.32px;
          }

          p {
            color: #07672F;
            font-family: "Helvetica";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      &__area-container {
        background-color: #007F2D;
      }

      &__title-doubt {
        color: #FFF;
        text-align: center;
        font-family: Antonio;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.64px;
        text-transform: uppercase;
      }

      &__subtitle-doubt {
        color: #FFF;
        text-align: center;
        font-family: "Helvetica";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-transform: none;
      }

      &__input {
        background-color: transparent;
        color: #E3E3E3 !important;
        font-family: "Helvetica";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-color: white !important;
      }

      &__textarea {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 8px;
        color: #E3E3E3 !important;
        font-family: "Helvetica";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: none;

        &::placeholder {
          color: #E3E3E3 !important;
        }
      }

      .button-generic {
        background-color: white;
        color: #07672F;
      }
    }

    .state-grid {
      padding-right: 10px
    }

    // -- Errors --
    .error {
      @include themeSecondary();
      &__title {
        line-height: normal;
      }
      &__description {
        color: #007F2D;
        font-family: "Helvetica";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      &__li {}
    }

    // -- Footer -- 
    .footer {
      &__text {
        color: #BABBBF !important;
        text-align: center !important;
        font-family: Helvetica !important;
        font-size: 10px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        text-decoration-line: none !important;
        text-transform: uppercase !important;
      }
    }

    // -- Loader --
    .app-loading {
      .spinner{
        .path {}
      }
    }
  // -- Finish Views --
}