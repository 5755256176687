.slick-next,
.slick-prev {
  top: 42%;
}


@media only screen and (max-width: 1279px) {

  .slick-next,
  .slick-prev {
    top: 52%;
  }
}

@media only screen and (min-width: 1279px) {

  .slick-next,
  .slick-prev {
    top: 52%;
  }
}

@media only screen and (max-width: 1023px) {

  .slick-next,
  .slick-prev {
    top: 42%;
  }
}