@mixin text($property, $color: '', $size: '', $weight: '', $family: '', $height: '', $transform: '', $align: '') {
  #{$property} {
    font-size: $size;
    font-weight: $weight;
    font-family: $family;
    text-transform: $transform;
    color: $color;
    line-height: $height;
    text-align: $align;
  }
}